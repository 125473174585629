import {createBrowserRouter,} from "react-router-dom";
import MarketingLayout from "./layouts/MarketingLayout";
import Urls from "./constants/Urls";
import HomePage from "./pages/marketing/HomePage";
import ErrorPg from "./pages/ErrorPg";
import NoteDashboardPg from "./pages/notes/NoteDashboardPg";
import NotePg from "./pages/notes/NotePg";
import CategoryDashboardPg from "./pages/notes/CategoryDashboardPg";

const Router = createBrowserRouter([
  {
    path: Urls.AbsoluteUrls.root,
    element: <MarketingLayout/>,
    errorElement: <ErrorPg/>,
    children: [
      {
        path: Urls.RelativeUrls.root,
        element: <HomePage/>,
      },
      {
        path: Urls.RelativeUrls.categoryRoot,
        element: <CategoryDashboardPg/>
      },
      {
        path: Urls.RelativeUrls.notesRoot,
        element: <NoteDashboardPg/>
      },
      {
        path: Urls.RelativeUrls.noteRoot,
        element: <NotePg/>,
      }
    ]
  },
]);

export default Router;