import "./Banner.scss";

interface Props {
  background: any;
}

const BannerCmp = (props: Props) => {
  const background = props.background;
  return (
    <div id="banner" data-testid="banner"
         style={{backgroundImage: `linear-gradient(rgba(0,0,0,.5),rgba(0,0,0,.5)),url(${background})`}}>
      <div className="container">
        <div>
          <h1>Software Engineering, Consulting & Mentoring</h1>
          <p>
            Ravelo Systematic Solutions Inc. is a sole proprietary corporation
            created for multiple purposes; Software engineering, CI/CD architecture
            design & implementation, teaching and mentoring people within the IT spectrum.
          </p>
        </div>
      </div>
    </div>
  );
}

export default BannerCmp;