import AppVersionCmp from "../components/AppVersionCmp";
import SitesNavBarCmp from "../components/SitesNavBarCmp";
import FooterCmp from "../components/FooterCmp";
import {Outlet} from "react-router-dom";

const MarketingLayout = () => {

  const name = process.env.REACT_APP_NAME || "";
  const version = process.env.REACT_APP_VERSION || "";
  const deployment = process.env.REACT_APP_DEPLOYMENT || "";
  const commit = process.env.REACT_APP_COMMIT || "";
  const env = process.env.NODE_ENV || "local";

  return (
    <div id="marketing">
      <SitesNavBarCmp/>
      <Outlet/>
      <FooterCmp>
        <>
          © {new Date().getFullYear()} Copyright: Ravelo{" "}
          <AppVersionCmp name={name}
                         version={version}
                         deployment={deployment}
                         commit={commit}
                         env={env}/>
        </>
      </FooterCmp>
    </div>
  )
}

export default MarketingLayout;