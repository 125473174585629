import ReactGA from "react-ga4";

const nodeEnv = process.env.NODE_ENV || "development";
const trackingId = process.env.REACT_APP_GA_TID || null;
const isTestMode = nodeEnv === "development";
const options = {
  testMode: isTestMode,
};

export const InitGA = () => {

  if (trackingId !== null) {
    console.log("tracking id set", trackingId, nodeEnv, isTestMode);
    ReactGA.initialize(trackingId, options);
    ReactGA.event({
      category: "sample category",
      action: "sample action",
      label: "sample label",
    });
  }

}
