import {Badge, Card} from "react-bootstrap";
import {Link} from "react-router-dom";
import Urls from "../constants/Urls";
// import {Action, Category, useAnalyticsEventTracker} from "../utilities/GAUtil";

interface Props {
  id: string;
  category: string;
  categoryDescription: string;
  notesLength: number;
  className?: string;
}

const CategoryListItemCmp = (props: Props) => {
  
  // const gaEventTracker = useAnalyticsEventTracker(Category.navigation);

  return (
    <Card as={Link}
          to={Urls.GeneratedUrls.absoluteCategory(props.id)}
          // onClick={() => gaEventTracker(Action.click, `clicked category link: ${props.category}`)}
          key={props.id}
          className={props?.className + " category-index-item"}>
      <Card.Body>
        <Card.Title className="text-start text-wrap clearfix">
          <small className="float-end">
            <Badge pill bg="dark">{props.notesLength} Note(s)</Badge>
          </small>
          <span>
            {props.category}
          </span>
        </Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          {props.categoryDescription}
        </Card.Subtitle>
      </Card.Body>
    </Card>
  )
}

export default CategoryListItemCmp;