import {useNoteState} from "../../providers/NotesProvider";
import LoadingCmp from "../../components/LoadingCmp";
import EmptyCmp from "../../components/EmptyCmp";
import {Link, useParams} from "react-router-dom";
import {Breadcrumb, Container} from "react-bootstrap";
import {INote} from "../../apis/NotesDB";
import Moment from "react-moment";
import MarkdownRendererCmp from "../../components/MarkdownRendererCmp";
import slugify from "slugify";
import Urls from "../../constants/Urls";

const NotePg = () => {

  const params = useParams();
  const {state} = useNoteState();

  if (state.apiStatus === 'INITIALIZING') {
    return <LoadingCmp/>
  }

  if (state.notes.length <= 0) {
    return <EmptyCmp/>
  }

  const item = state.notes.find((n: INote) => n.id === params.noteId && slugify(n.category).toLowerCase() === params.categoryId);

  return (
    <Container>
      <div className="mt-4 mb-4">
        <h1 className="mb-1">
          {item?.title}
        </h1>
        <small>
          <Breadcrumb>
            <Link className="breadcrumb-item" to={Urls.AbsoluteUrls.notesRoot}>Notes</Link>
            <Link className="breadcrumb-item" to={Urls.GeneratedUrls.absoluteCategory(slugify(item?.category || "").toLowerCase())}>{item?.category}</Link>
            <Breadcrumb.Item active>
              <Moment format="MMMM Do, YYYY">{item?.publishedAt}</Moment>
            </Breadcrumb.Item>
          </Breadcrumb>
        </small>
      </div>

      <MarkdownRendererCmp value={item?.content || ""}/>
    </Container>
  )
}

export default NotePg;