import {createContext, Dispatch, ReactNode, useContext, useEffect, useReducer} from "react";
import NotesDB, {ICategory, INote} from "../apis/NotesDB";
import slugify from "slugify";

/**
 * Interfaces
 */

// Describes the data that is managed by the NotesProvider.
export interface IState {
  apiStatus: string;
  notes: INote[];
  categories: ICategory[];
  error?: string;
}

// Describes the properties the NotesProvider component
// supports.
interface INotesProviderProps {
  children: ReactNode
}

// Describes the state and the actions the NotesProvider performs
interface INoteContext {
  state: IState;
  dispatch: Dispatch<IAction>;
}

// Describes the actions and the payloads (if applicable)
// that each action processes.
export type IAction =
  | { type: 'POPULATE', payload: {notes: INote[], categories: ICategory[]} }
  | { type: 'ERROR', payload: string }

// Manages the state of the NotesProvider
const reducer = (state: IState, action: IAction) => {
  switch (action.type) {
    case 'POPULATE':
      return {
        ...state,
        apiStatus: 'LOADED',
        notes: action.payload.notes,
        categories: action.payload.categories
      };
    case 'ERROR':
      return {
        ...state,
        apiStatus: 'ERROR',
        notes: [],
        error: action.payload
      };
    default:
      return state;
  }
}

// stores the data available in the component's run time.
const initialState = {
  apiStatus: 'INITIALIZING',
  notes: [],
  categories: []
}

const NoteContext = createContext<INoteContext>({
  state: initialState,
  dispatch: (action) => console.error("Dispatched action outside of an NoteContext provider", action)
});

export const useNoteState = () => useContext(NoteContext);

export const NotePvr = ({children}: INotesProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    if (state.apiStatus === 'INITIALIZING') {
      NotesDB.index()
        .then(data => {

          let categories: {[id: string]: ICategory} = {};
          const notes = data;
          notes.forEach((note) => {
            const id = slugify(note.category).toLowerCase();
            if(id in categories) {
              categories[id].notes.push(note)
            } else {
              categories[id] = {
                id: id,
                category: note.category,
                categoryDescription: note.categoryDescription,
                notes: [note]
              }
            }
          });

          dispatch({
            type: "POPULATE",
            payload: {
              notes: notes,
              categories: Object.values(categories)
            },
          });
        })
        .catch(err => {
          dispatch({
            type: "ERROR",
            payload: err,
          });
        });
    }
  });

  return (
    <NoteContext.Provider value={{state, dispatch}}>
      {children}
    </NoteContext.Provider>
  )
}

export default NotePvr;