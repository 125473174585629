import Router from "./Router";
import {RouterProvider} from "react-router-dom";
import NotesProvider from "./providers/NotesProvider";

function App() {
  return (
    <NotesProvider>
      <RouterProvider router={Router}/>
    </NotesProvider>
  );
}

export default App;
