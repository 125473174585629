import {Breadcrumb, Col, Container, Row} from "react-bootstrap";
import NoteListItemCmp from "../../components/NoteListItemCmp";
import {useNoteState} from "../../providers/NotesProvider";
import LoadingCmp from "../../components/LoadingCmp";
import EmptyCmp from "../../components/EmptyCmp";
import CategoryListItemCmp from "../../components/CategoryListItemCmp";

const NoteDashboardPg = () => {

  const {state} = useNoteState();
  if (state.apiStatus === 'INITIALIZING') {
    return <LoadingCmp/>
  }

  if (state.notes.length <= 0) {
    return <EmptyCmp/>
  }

  return (
    <Container>
      <div className="mt-4 mb-4">
        <h1 className="mb-1">
          Notes
        </h1>
        <small>
          <Breadcrumb>
            <Breadcrumb.Item active>Notes</Breadcrumb.Item>
          </Breadcrumb>
        </small>
        <p>
          Note to self - Here's a list of categories/notes that I have done/learnt in the past. Try to keep learning,
          get better and keep focus on what matters to you - learning new technologies, languages, patterns and anything
          new that's out there that could help you become a better engineer.
          Try to keep your notes simple with one level categories. If you need to add complicated categories, changes
          are,
          you're most likely over thinking/complicating it.
        </p>

        <div>
          <h3>Categories</h3>
          <Row>
            {state.categories.map(item => {
              return (
                <Col key={item.id}>
                  <CategoryListItemCmp key={item.id}
                                       id={item.id}
                                       category={item.category}
                                       notesLength={item.notes.length}
                                       categoryDescription={item.categoryDescription}/>
                </Col>
              )
            })}
          </Row>
        </div>

        <div className="mt-4">
          <h3>Notes</h3>
          <Row>
            {state.notes.map(item => {
              return (
                <Col key={item.id} sm="12" md="4" className="d-flex align-items-stretch">
                  <NoteListItemCmp id={item.id}
                                   title={item.title}
                                   category={item.category}
                                   publishedAt={item.publishedAt}
                                   className="mt-2 w-100"/>
                </Col>
              )
            })}
          </Row>
        </div>

      </div>
    </Container>
  )
}

export default NoteDashboardPg;