import {Breadcrumb, Col, Container, Row} from "react-bootstrap";
import NoteListItemCmp from "../../components/NoteListItemCmp";
import {useNoteState} from "../../providers/NotesProvider";
import LoadingCmp from "../../components/LoadingCmp";
import EmptyCmp from "../../components/EmptyCmp";
import {Link, useParams} from "react-router-dom";
import Urls from "../../constants/Urls";

const NoteDashboardPg = () => {

  const params = useParams();
  const {state} = useNoteState();
  if (state.apiStatus === 'INITIALIZING') {
    return <LoadingCmp/>
  }

  if (state.notes.length <= 0) {
    return <EmptyCmp/>
  }

  const item = state.categories.find(c => c.id === params.categoryId)

  return (
    <Container>
      <div className="mt-4 mb-4">
        <h1 className="mb-1">
          {item?.category}
        </h1>
        <small>
          <Breadcrumb>
            <Link className="breadcrumb-item" to={Urls.AbsoluteUrls.notesRoot}>Notes</Link>
            <Breadcrumb.Item active>{item?.category}</Breadcrumb.Item>
          </Breadcrumb>
        </small>
        <p>{item?.categoryDescription}</p>

        <Row>
          {item?.notes.map(note => {
            return (
              <Col key={note.id} sm="12" md="4" className="d-flex align-items-stretch">
                <NoteListItemCmp id={note.id}
                                 title={note.title}
                                 category={note.category}
                                 publishedAt={note.publishedAt}
                                 className="mt-2 w-100"/>
              </Col>
            )
          })}
        </Row>
      </div>
    </Container>
  )
}

export default NoteDashboardPg;