interface Props {
  name: string;
  version: string;
  commit: string;
  deployment: string;
  env: string;
}

const AppVersionCmp = (props: Props): JSX.Element => {
  let text = `${props.commit}.${props.name}.${props.version}`;
  if (props.env) text += `.${props.env}`;
  if (props.deployment) text += `.${props.deployment}`;

  return (
    <span data-testid="version-text" className="text-muted">
            {text}
        </span>
  )
};

export default AppVersionCmp;