import {Col, Container, Row} from "react-bootstrap";
import dockerImg from "../assets/imgs/technology/docker.png";
import virtualbox from "../assets/imgs/technology/virtualbox.png";
import k8Img from "../assets/imgs/technology/k8.png";
import vagrantImg from "../assets/imgs/technology/vagrant.png";

const VirtualizationCmp = () => {
  return (
    <Container className="mt-5 mb-5 vm">
      <div className="text-center mt-5">
        <h3>Virtualization</h3>
        <p className="mx-auto mb-5">
          I've worked with a few virtualization tools so I can help create, build,
          deploy, integrate & run images, automate deployments and more.
        </p>
      </div>
      <Row className="text-center">
        <Col xs="6" sm={{span: 2, offset: 2}}>
          <img src={dockerImg} className="img-responsive" alt="Doccker"/>
          <h4>Docker</h4>
        </Col>
        <Col xs="6" sm={{span: 2}}>
          <img src={virtualbox} className="img-responsive" alt="VirtualBox"/>
          <h4>VirtualBox</h4>
        </Col>
        <Col xs="6" sm={{span: 2}}>
          <img src={vagrantImg} className="img-responsive" alt="Vagrant"/>
          <h4>Vagrant</h4>
        </Col>
        <Col xs="6" sm={{span: 2}}>
          <img src={k8Img} className="img-responsive" alt="Kubernetes"/>
          <h4>Kubernetes</h4>
        </Col>
      </Row>
    </Container>
  );
}

export default VirtualizationCmp;