import PLanguagesCmp from "../../components/PLanguagesCmp";
import DatabasesCmp from "../../components/DatabasesCmp";
import VirtualizationCmp from "../../components/VirtualizationCmp";
import BannerCmp from "../../components/BannerCmp";
import raveloBanner from "../../assets/imgs/banners/ravelo-banner.jpg";

const HomePage = () => {
  return (
    <>
      <BannerCmp background={raveloBanner}/>
      <PLanguagesCmp/>
      <hr className="mb-5 mt-5"/>
      <DatabasesCmp/>
      <hr className="mb-5 mt-5"/>
      <VirtualizationCmp/>
    </>
  )
}

export default HomePage;