import Moment from "react-moment";
import {Card} from "react-bootstrap";
import {Link} from "react-router-dom";
import Urls from "../constants/Urls";
import slugify from "slugify";
// import {Action, Category, useAnalyticsEventTracker} from "../utilities/GAUtil";

interface Props {
  id: string;
  category: string;
  title: string;
  publishedAt: Date;
  className?: string;
}

const NoteListItemCmp = (props: Props) => {

  // const gaEventTracker = useAnalyticsEventTracker(Category.navigation);

  return (
    <Card as={Link}
          to={Urls.GeneratedUrls.absoluteNote(slugify(props.category).toLowerCase(), props.id)}
          // onClick={() => gaEventTracker(Action.click, `clicked note link: ${props.title}`)}
          key={props.id}
          className={props?.className + " note-index-item"}>
      <Card.Body>
        <Card.Title className="text-start text-wrap">
          {props.title}
        </Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          <Moment format="MMMM Do, YYYY">
            {props.publishedAt}
          </Moment>
        </Card.Subtitle>
      </Card.Body>
    </Card>
  )
}

export default NoteListItemCmp;