import {Col, Container, Row} from "react-bootstrap";
import pythonImg from "../assets/imgs/technology/python.png";
import nodeImg from "../assets/imgs/technology/nodejs-icon.svg";
import coldfusionImg from "../assets/imgs/technology/coldfusion.png";
import phpImg from "../assets/imgs/technology/php.png";
import javaImg from "../assets/imgs/technology/java.png";
import vbImg from "../assets/imgs/technology/visual_basic.png";
import rubyImg from "../assets/imgs/technology/ruby.jpeg";
import goImg from "../assets/imgs/technology/go.png";

const PLanguagesCmp = () => {
  return (
    <Container className="mt-5 mb-2 pl">
      <div className="text-center mt-5">
        <h3>Programming languages</h3>
        <p className="mx-auto mb-5">
          This is probably the most fun part of my career. I've designed, built, deploy & maintain
          using many technologies. I like to learn new languages too, each language is different and
          has changed my way of solving problems.
        </p>
      </div>
      <Row className="text-center">
        <Col xs="6" md="3" sm={{span: 3}}>
          <img src={vbImg} className="img-responsive" alt="Visual Studio 6.0"/>
          <h4>Visual Basic 6.0</h4>
        </Col>
        <Col xs="6" md="3" sm={{span: 3}}>
          <img src={coldfusionImg} className="img-responsive" alt="Coldfusion"/>
          <h4>Coldfusion</h4>
        </Col>
        <Col xs="6" md="3" sm={{span: 3}}>
          <img src={phpImg} className="img-responsive" alt="PHP"/>
          <h4>PHP</h4>
        </Col>
        <Col xs="6" md="3" sm={{span: 3}}>
          <img src={pythonImg} className="img-responsive" alt="Python"/>
          <h4>Python</h4>
        </Col>
        <Col xs="6" md="3" sm={{span: 3}}>
          <img src={nodeImg} className="img-responsive" alt="NodeJS"/>
          <h4>NodeJS</h4>
        </Col>
        <Col xs="6" md="3" sm={{span: 3}}>
          <img src={rubyImg} className="img-responsive" alt="Ruby"/>
          <h4>Ruby</h4>
        </Col>
        <Col xs="6" md="3" sm={{span: 3}}>
          <img src={javaImg} className="img-responsive" alt="Java"/>
          <h4>Java</h4>
        </Col>
        <Col xs="6" md="3" sm={{span: 3}}>
          <img src={goImg} className="img-responsive" alt="Golang"/>
          <h4>Go</h4>
        </Col>
      </Row>
    </Container>
  );
}

export default PLanguagesCmp;