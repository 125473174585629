import {Col, Container, Row} from "react-bootstrap";
import mongoImg from "../assets/imgs/technology/mongo2.png";
import redisImg from "../assets/imgs/technology/redis.png";
import MysqlImg from "../assets/imgs/technology/mysql.png"
import DdbImg from "../assets/imgs/technology/dynamodb.png"

const DatabasesCmp = () => {
  return (
    <Container className="mt-5 mb-2 db">
      <div className="text-center mt-5">
        <h3>Databases</h3>
        <p className="mx-auto mb-5">
          In conjunction with programming languages, I have used databases to be able to
          manage applications' data & state.
        </p>
      </div>
      <Row className="text-center">
        <Col xs="6" sm={{span: 2, offset: 2}}>
          <img src={MysqlImg} style={{height: "50px"}} className="img-responsive" alt="MySQL"/>
          <h4>MySQL</h4>
        </Col>
        <Col xs="6" sm={{span: 2}}>
          <img src={mongoImg} style={{height: "50px"}} className="img-responsive" alt="MongoDB"/>
          <h4>MongoDB</h4>
        </Col>
        <Col xs="6" sm={{span: 2}}>
          <img src={redisImg} style={{height: "50px"}} className="img-responsive" alt="Redis"/>
          <h4>Redis</h4>
        </Col>
        <Col xs="6" sm={{span: 2}}>
          <img src={DdbImg} style={{height: "50px"}} className="img-responsive" alt="DynamoDB"/>
          <h4>DynamoDB</h4>
        </Col>
      </Row>
    </Container>
  );
}

export default DatabasesCmp;