import React from "react";
// import {BsMenuButtonWideFill} from 'react-icons/bs';
import {Link} from "react-router-dom";
import "./SitesNavBar.scss";
import Urls from "../constants/Urls";
// import {Action, Category, useAnalyticsEventTracker} from "../utilities/GAUtil";

const SitesNavBarCmp = (): JSX.Element => {

  // const gaEventTracker = useAnalyticsEventTracker(Category.navigation);

  return (
    <nav id="sites-navbar" className="navbar navbar-expand-lg bg-body-tertiary navbar-light bg-light sticky-top">
      <div className="container-fluid">
        <Link className="navbar-brand"
              data-testid="marketing-anchor"
              // onClick={() => gaEventTracker(Action.click, 'clicked home link')}
              to={Urls.AbsoluteUrls.root}>Ravelo</Link>
        {/* <button className="navbar-toggler btn btn-link"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarText"
                aria-controls="navbarText"
                aria-expanded="false"
                // onClick={() => gaEventTracker(Action.click, 'clicked menu toggle')}
                aria-label="Toggle navigation">
          <BsMenuButtonWideFill/>
        </button>
        <div className="collapse navbar-collapse" id="navbarText">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link className="nav-link"
                    data-testid="notes-anchor"
                    // onClick={() => gaEventTracker(Action.click, 'clicked notes link')}
                    to={Urls.AbsoluteUrls.notesRoot}>Notes</Link>
            </li>
          </ul>
        </div> */}
      </div>
    </nav>
  )
};

export default SitesNavBarCmp;