import {atomDark as style} from "react-syntax-highlighter/dist/esm/styles/prism";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";

interface Props {
  language: string;
  children: any;
}

const CodeRendererCmp = (props: Props) => {
  return (
    <SyntaxHighlighter className="code-highlighter" language={props.language} style={style} showLineNumbers={true}>
      {props.children}
    </SyntaxHighlighter>
  )
}

export default CodeRendererCmp;