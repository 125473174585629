import ReactMarkdown from "react-markdown";
import CodeRendererCmp from "./CodeRendererCmp";
import supersub from 'remark-supersub'

interface Props {
  value: string;
}

const MarkdownRendererCmp = (props: Props) => {
  return (
    <ReactMarkdown remarkPlugins={[supersub]} components={{
      code({node, inline, className, children, ...props}) {
        const match = /language-(\w+)/.exec(className || '')
        console.log('match', match, props, children);
        return !inline && match ? (
          <CodeRendererCmp language={match[1]}>
            {String(children).trim()}
          </CodeRendererCmp>
        ) : (
          <code className={className} {...props}>
            {children}
          </code>
        )
      }
    }}>
      {props.value || ""}
    </ReactMarkdown>
  )
}

export default MarkdownRendererCmp;