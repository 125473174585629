import axios, {AxiosResponse} from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export interface ICategory {
  id: string;
  category: string;
  categoryDescription: string;
  notes: INote[]
}

// Describes the data we receive from the notes API.
export interface INote {
  id: string;
  title: string;
  category: string;
  categoryDescription: string;
  content: string;
  publishedAt: Date;
}

export interface INoteIndexResponse {
  items: INote[];
}

const NotesDB = {
  index: async () => {
    return axios
      .get(`${API_URL}/notes`)
      .then((d: AxiosResponse<INoteIndexResponse>) => {
        return d.data.items;
      });
  },
};

export default NotesDB;
