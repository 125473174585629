const AbsoluteUrls = {
  root: "/",
  notesRoot: "/notes",
};

const RelativeUrls = {
  root: "",
  notesRoot: "notes",
  categoryRoot: "notes/:categoryId",
  noteRoot: "notes/:categoryId/:noteId",
};

const GeneratedUrls = {
  absoluteNote: (categoryId: string, noteId: string) => `/notes/${categoryId}/${noteId}`,
  absoluteCategory: (categoryId: string) => `/notes/${categoryId}`,
};

const Urls = {
  AbsoluteUrls,
  RelativeUrls,
  GeneratedUrls,
};

export default Urls;
