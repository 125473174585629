import {useRouteError} from "react-router-dom";

const ErrorPg = () => {
  const err = useRouteError();
  // @ts-ignore
  const text = err.statusText || err.message;

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{text}</i>
      </p>
    </div>
  );
}

export default ErrorPg;