import "./Footer.scss";

interface Props {
  children: JSX.Element;
};

const FooterCmp = (props: Props) => {
  return (
    <footer className="fixed-bottom">
      {props.children}
    </footer>
  )
}

export default FooterCmp;